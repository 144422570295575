import { MetaData } from "../interfaces/config/metaData";

/**
 * Default description used for meta data.
 *
 * @constant {string}
 */
const description =
  "Direct and develop your mobile application, software or website project with the help of the most innovative and nimble talents on the market.";

/**
 * Metadata for different pages in the application.
 *
 * @type {MetaData}
 */
const metaData: MetaData = {
  home: {
    title:
      "CanGuru  |  Emerging Technologies, Mobile Applications, Software, Web Design & Development",
    description,
  },
  notFound: {
    title: "CanGuru | Not Found.",
    description,
  },
};

export default metaData;
