import React from "react";
import { CustomLayoutProps } from "../../interfaces/customLayout";
import ScrollToTop from "../../router/scrollToTop";
import { ToastContainer } from "react-toastify";
import Loader from "../../connected-components/loader";

const CustomLayout: React.FC<CustomLayoutProps> = ({ children }) => {
  return (
    <div>
      <ScrollToTop />
      <ToastContainer
        position={"bottom-left"}
        toastStyle={{
          borderRadius: 12,
          background: "rgba(243,243,243,0.2)",
          backdropFilter: "blur( 20px )",
          color: "#fff",
          border: "1px solid rgba( 255, 255, 255, 0.18 )",
        }}
      />
      {children}
      <Loader />
    </div>
  );
};

export default CustomLayout;
