import React from "react";
import styles from "./loader.module.scss";
import { LoadingProps } from "../../interfaces/connected-components/loading";
import { connect } from "react-redux";
import { RootState } from "../../redux/store";

/**
 * Loader component that displays a loading indicator.
 * @param {LoadingProps} loading - Loading state from Redux store.
 * @returns {JSX.Element} Loading indicator component.
 */
const Loader: React.FC = ({ loading }: LoadingProps): JSX.Element => {
  const { isVisible } = loading || {};
  return isVisible ? (
    <div
      className={`${styles["loading-container"]} d-flex justify-content-center align-items-center`}
    >
      <div className={styles["loader"]}></div>
    </div>
  ) : (
    <div></div>
  );
};
const mapStateToProps = (state: RootState) => ({
  loading: state.loading,
});

export default connect(mapStateToProps, null)(Loader);
