import environment from "../environment/environment";

export const api = {
  clips: {
    streams: (service: string) => `${environment.baseUrl}${service}/streams`,
    sdp: (service: string, streamId: any) =>
      `${environment.baseUrl}${service}/streams/${streamId}/sdp`,
    stream: (service: string, streamId: any) =>
      `${environment.baseUrl}${service}/streams/${streamId}`,
    ice: (service: string, streamId: any) =>
      `${environment.baseUrl}${service}/streams/${streamId}/ice`,
  },
};

export const presenterInputByService = {
  talks: {
    source_url: "https://canguru.au/kisspng.png",
  },
  clips: {
    presenter_id: "rian-lZC6MmWfC1",
    driver_id: "ibU83RPsbB",
  },
};
