import React from "react";
import styles from "./notFound.module.scss";
import generateMeta from "../../../shared/services/metadataService";
import metaData from "../../../config/metaData";
/**
 * NotFound component displays a simple "Not found - 404" message when the requested page is not found.
 * @returns {JSX.Element} The NotFound component JSX
 */
const NotFound = (): JSX.Element => {
  return (
    <div
      className={`${styles["notFound-container"]} d-flex justify-content-center align-items-center`}
    >
      {generateMeta({
        title: metaData.notFound.title,
        description: metaData.notFound.description,
      })}
      <h1>404</h1>
      <p>Page Missing in Action!</p>
    </div>
  );
};

export default NotFound;
