import { EventEmitter } from "events";
import http from "../http";
import { api, presenterInputByService } from "../../config/api";

const streamingText = new EventEmitter();

const service = "clips";

const connect = async (streamWarmup: boolean) => {
  const url = api.clips.streams(service);
  const body = {
    ...presenterInputByService[service as keyof typeof presenterInputByService],
    stream_warmup: streamWarmup,
  };

  return await http.post(url, body);
};

const sendAnswer = async (streamId: any, answer: any, sessionId: any) => {
  const url = api.clips.sdp(service, streamId);
  const body = { answer, session_id: sessionId };

  return await http.post(url, body);
};

const startStream = async (
  streamId: any,
  sessionId: any,
  streamingText: string,
) => {
  const url = api.clips.stream(service, streamId);
  const body = {
    script: {
      type: "text",
      input: streamingText,
      provider: {
        type: "microsoft",
        voice_id: "en-US-AndrewNeural",
        voice_config: {
          style: "Cheerful",
        },
      },
    },
    background: {
      color: "#ffffff",
    },
    config: {
      result_format: "mp4",
    },
    session_id: sessionId,
  };

  return await http.post(url, body);
};

const destroyStream = async (streamId: any, sessionId: any) => {
  const url = api.clips.stream(service, streamId);
  // const body = { session_id: sessionId };

  return await http.delete(url);
};

const sendIceCandidate = async (
  streamId: any,
  candidate: any,
  sessionId: any,
) => {
  const url = api.clips.ice(service, streamId);
  const body = candidate
    ? { ...candidate, session_id: sessionId }
    : { session_id: sessionId };

  return await http.post(url, body);
};

const streaming = {
  streamingText,
  connect,
  sendAnswer,
  startStream,
  destroyStream,
  sendIceCandidate,
};

export default streaming;
