import environment from "../environment/environment";

interface Headers {
  [key: string]: string;
}

interface FetchOptions {
  method: string;
  headers: Headers;
  body?: string;
  mode: RequestMode;
}

/**
 * Error handler function that displays error messages or copies the error to the clipboard (if in debug mode).
 * @param {any} err - The error object.
 * @returns {boolean} Returns true to indicate that the error has been handled.
 */
const handleError = (err: any): boolean => {
  try {
    console.log("Error calling api => ", err);
    return true;
  } catch (e) {
    return true;
  }
};

/**
 * Http service that provides methods for making HTTP requests.
 * @type {object}
 */
const http = {
  /**
   * Performs a GET request.
   * @param {string} url - The URL to send the GET request to.
   * @param {Headers} headers - Optional headers to be included in the request.
   * @returns {Promise<any>} A Promise that resolves to the response data.
   */
  get: (url: string, headers: Headers = {}): Promise<any> => {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "GET",
        headers: {
          ...headers,
          Authorization: `Basic ${environment.apiKey}`,
        },
        mode: "cors",
      })
        .then((res: Response) => {
          if (!res.ok) {
            handleError(res);
            reject(res.json());
          } else {
            res.json().then(resolve).catch(reject);
          }
        })
        .catch((err: any) => {
          handleError(err);
          reject(err);
        });
    });
  },

  /**
   * Performs a POST request.
   * @param {string} url - The URL to send the POST request to.
   * @param {any} body - The request body.
   * @param {Headers} headers - Optional headers to be included in the request.
   * @returns {Promise<any>} A Promise that resolves to the response data.
   */
  post: (url: string, body: any, headers: Headers = {}): Promise<any> => {
    return new Promise((resolve, reject) => {
      const options: FetchOptions = {
        method: "POST",
        headers: {
          ...headers,
          Authorization: `Basic ${environment.apiKey}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify(body),
      };

      fetch(url, options)
        .then((res: Response) => {
          if (!res.ok) {
            handleError(res);
            res.json().then(reject).catch(reject);
          } else {
            res.json().then(resolve).catch(reject);
          }
        })
        .catch((err: any) => {
          handleError(err);
          reject(err);
        });
    });
  },

  /**
   * Performs a DELETE request.
   * @param {string} url - The URL to send the DELETE request to.
   * @param {Headers} headers - Optional headers to be included in the request.
   * @returns {Promise<any>} A Promise that resolves to the response data.
   */
  delete: (url: string, headers: Headers = {}): Promise<any> => {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "DELETE",
        headers: {
          ...headers,
          Authorization: `Basic ${environment.apiKey}`,
        },
        mode: "cors",
      })
        .then((res: Response) => {
          if (!res.ok) {
            handleError(res);
            res.json().then(reject).catch(reject);
          } else {
            res.json().then(resolve).catch(reject);
          }
        })
        .catch((err: any) => {
          handleError(err);
          reject(err);
        });
    });
  },
};

export default http;
