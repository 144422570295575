import React, { useCallback, useEffect, useRef, useState } from "react";
import { services } from "../../../../api";
import { isBrowserDefined } from "../../../../shared/services/browserService";

const Streaming: React.FC<{ hideIdeal: boolean }> = ({ hideIdeal = false }) => {
  const idleVideoRef = useRef<HTMLVideoElement | null>(null);
  const streamVideoRef = useRef<HTMLVideoElement | null>(null);
  const peerConnectionRef = useRef<RTCPeerConnection | null>(null);
  const pcDataChannelRef = useRef<RTCDataChannel | null>(null);
  const streamIdRef = useRef<string | null>(null);
  const sessionIdRef = useRef<string | null>(null);
  const isStreamReadyRef = useRef<boolean>(false);
  const lastBytesReceivedRef = useRef<number | null>(null);
  const videoIsPlayingRef = useRef<boolean>(false);
  const statsIntervalIdRef = useRef<NodeJS.Timeout | null>(null);

  const [streamVideoOpacity, setStreamVideoOpacity] = useState<number>(0);

  const connect = useCallback(async () => {
    if (
      peerConnectionRef.current &&
      peerConnectionRef.current.connectionState === "connected"
    )
      return;

    stopAllStreams();
    closePC();

    try {
      const sessionResponse = await services.streaming.connect(true);
      const {
        id: newStreamId,
        offer,
        ice_servers: iceServers,
        session_id: newSessionId,
      } = sessionResponse;
      streamIdRef.current = newStreamId;
      sessionIdRef.current = newSessionId;
      try {
        const answer = await createPeerConnection(offer, iceServers);
        await services.streaming.sendAnswer(newStreamId, answer, newSessionId);
      } catch (e) {
        console.error("Error during streaming setup", e);
        stopAllStreams();
        closePC();
        return;
      }
    } catch (error) {
      console.error("Error connecting:", error);
    }
  }, []);

  const createPeerConnection = useCallback(
    async (offer: RTCSessionDescriptionInit, iceServers: RTCIceServer[]) => {
      if (!peerConnectionRef.current) {
        const newPeerConnection = new RTCPeerConnection({ iceServers });
        peerConnectionRef.current = newPeerConnection;
        pcDataChannelRef.current =
          peerConnectionRef.current.createDataChannel("JanusDataChannel");

        peerConnectionRef.current.addEventListener(
          "icegatheringstatechange",
          onIceGatheringStateChange,
          true,
        );
        peerConnectionRef.current.addEventListener(
          "icecandidate",
          onIceCandidate,
          true,
        );
        peerConnectionRef.current.addEventListener(
          "iceconnectionstatechange",
          onIceConnectionStateChange,
          true,
        );
        peerConnectionRef.current.addEventListener(
          "connectionstatechange",
          onConnectionStateChange,
          true,
        );
        peerConnectionRef.current.addEventListener(
          "signalingstatechange",
          onSignalingStateChange,
          true,
        );
        peerConnectionRef.current.addEventListener("track", onTrack, true);
        pcDataChannelRef.current?.addEventListener(
          "message",
          handleStreamEvent,
        );
      }
      await peerConnectionRef.current.setRemoteDescription(
        new RTCSessionDescription(offer),
      );
      const sessionClientAnswer =
        await peerConnectionRef.current.createAnswer();
      let sdp = modifySDP(sessionClientAnswer.sdp || "");
      await peerConnectionRef.current.setLocalDescription({
        type: "answer",
        sdp,
      });

      return { type: "answer", sdp };
    },
    [],
  );

  const onIceGatheringStateChange = useCallback(() => {
    console.log(
      "iceGatheringState =>",
      peerConnectionRef.current?.iceGatheringState,
    );
  }, []);

  const onIceCandidate = useCallback((event: RTCPeerConnectionIceEvent) => {
    console.log("onIceCandidate", event);
    if (event.candidate) {
      const { candidate, sdpMid, sdpMLineIndex } = event.candidate;
      services.streaming.sendIceCandidate(
        streamIdRef.current!,
        {
          candidate,
          sdpMid,
          sdpMLineIndex,
        },
        sessionIdRef.current!,
      );
    } else {
      services.streaming.sendIceCandidate(
        streamIdRef.current!,
        null,
        sessionIdRef.current!,
      );
    }
  }, []);

  const onIceConnectionStateChange = useCallback(() => {
    console.log(
      "iceConnectionState =>",
      peerConnectionRef.current?.iceConnectionState,
    );
    if (
      peerConnectionRef.current?.iceConnectionState === "failed" ||
      peerConnectionRef.current?.iceConnectionState === "closed"
    ) {
      stopAllStreams();
      closePC();
    }
  }, []);

  const onConnectionStateChange = useCallback(() => {
    console.log(
      "connectionState =>",
      peerConnectionRef.current?.connectionState,
    );
    if (peerConnectionRef.current?.connectionState === "connected") {
      (window as any).ReactNativeWebView?.postMessage("connected");
      setTimeout(() => {
        if (!isStreamReadyRef.current) {
          isStreamReadyRef.current = true;
        }
      }, 5000);
    } else if (peerConnectionRef.current?.connectionState === "disconnected") {
      (window as any).ReactNativeWebView?.postMessage("disconnected");
      connect();
    }
  }, []);

  const onSignalingStateChange = useCallback(() => {
    console.log("signalingState =>", peerConnectionRef.current?.signalingState);
  }, []);

  const onTrack = useCallback((event: RTCTrackEvent) => {
    if (!event.track) return;

    let isActive = true;

    statsIntervalIdRef.current = setInterval(async () => {
      if (!isActive) {
        clearInterval(statsIntervalIdRef.current!);
        return;
      }

      try {
        const stats = await peerConnectionRef.current?.getStats(event.track);
        if (!stats) {
          throw new Error("Stats not available");
        }

        stats.forEach((report) => {
          if (!isActive) return;

          if (report.type === "inbound-rtp" && report.kind === "video") {
            const videoStatusChanged =
              videoIsPlayingRef.current !==
              report.bytesReceived > lastBytesReceivedRef.current!;

            if (videoStatusChanged) {
              videoIsPlayingRef.current =
                report.bytesReceived > lastBytesReceivedRef.current!;
              onVideoStatusChange(videoIsPlayingRef.current, event.streams[0]);
            }
            lastBytesReceivedRef.current = report.bytesReceived;
          }
        });
      } catch (error) {
        console.error("Error getting stats:", error);
        isActive = false;
        clearInterval(statsIntervalIdRef.current!);
      }
    }, 500);

    return () => {
      isActive = false;
      clearInterval(statsIntervalIdRef.current!);
    };
  }, []);

  const handleStreamEvent = useCallback((message: any) => {
    const [event, _] = message.data.split(":");
    let status;

    switch (event) {
      case "stream/started":
        status = "started";
        break;
      case "stream/done":
        status = "done";
        break;
      case "stream/ready":
        status = "ready";
        break;
      case "stream/error":
        status = "error";
        break;
      default:
        status = "dont-care";
        break;
    }
    (window as any).ReactNativeWebView?.postMessage(status);

    if (status === "ready") {
      setTimeout(() => {
        console.log("stream/ready");
        isStreamReadyRef.current = true;
      }, 1000);
    } else {
      console.log(event);
      // Update the stream event label if needed
    }
  }, []);

  const onVideoStatusChange = useCallback(
    (videoIsPlaying: boolean, stream: MediaStream) => {
      if (videoIsPlaying) {
        setStreamVideoOpacity(isStreamReadyRef.current ? 1 : 0);
        setStreamVideoElement(stream);
      } else {
        setStreamVideoOpacity(0);
      }

      if (streamVideoRef.current) {
        streamVideoRef.current.style.opacity = streamVideoOpacity.toString();
      }
      if (idleVideoRef.current) {
        idleVideoRef.current.style.opacity = (
          1 - streamVideoOpacity
        ).toString();
      }
    },
    [streamVideoOpacity],
  );

  const setStreamVideoElement = useCallback((stream: MediaStream) => {
    if (!stream) return;

    if (streamVideoRef.current) {
      streamVideoRef.current.srcObject = stream;
      streamVideoRef.current.loop = false;
      streamVideoRef.current.playsInline = true;
      streamVideoRef.current.autoplay = true;
      streamVideoRef.current.muted = false;
      streamVideoRef.current.play().catch((e) => {});
    }
  }, []);

  const playIdleVideo = useCallback(() => {
    if (idleVideoRef.current) {
      // Set the idle video source here if needed
    }
  }, []);

  const stopAllStreams = useCallback(() => {
    if (streamVideoRef.current && streamVideoRef.current.srcObject) {
      const tracks = (
        streamVideoRef.current.srcObject as MediaStream
      ).getTracks();
      tracks.forEach((track) => track.stop());
      streamVideoRef.current.srcObject = null;
      setStreamVideoOpacity(0);
    }
  }, []);

  const closePC = useCallback(() => {
    if (!peerConnectionRef.current) return;

    peerConnectionRef.current.close();
    peerConnectionRef.current.removeEventListener(
      "icegatheringstatechange",
      onIceGatheringStateChange,
      true,
    );
    peerConnectionRef.current.removeEventListener(
      "icecandidate",
      onIceCandidate,
      true,
    );
    peerConnectionRef.current.removeEventListener(
      "iceconnectionstatechange",
      onIceConnectionStateChange,
      true,
    );
    peerConnectionRef.current.removeEventListener(
      "connectionstatechange",
      onConnectionStateChange,
      true,
    );
    peerConnectionRef.current.removeEventListener(
      "signalingstatechange",
      onSignalingStateChange,
      true,
    );
    peerConnectionRef.current.removeEventListener("track", onTrack, true);
    pcDataChannelRef.current?.removeEventListener(
      "message",
      handleStreamEvent,
      true,
    );

    if (statsIntervalIdRef.current) {
      clearInterval(statsIntervalIdRef.current);
      statsIntervalIdRef.current = null;
    }
    isStreamReadyRef.current = false;
    setStreamVideoOpacity(0);
    peerConnectionRef.current = null;
  }, [
    onIceGatheringStateChange,
    onIceCandidate,
    onIceConnectionStateChange,
    onConnectionStateChange,
    onSignalingStateChange,
    onTrack,
  ]);

  const modifySDP = useCallback((sdp: string) => {
    if (!sdp.includes("VP8")) {
      sdp = sdp.replace(
        /m=video 9 UDP\/TLS\/RTP\/SAVPF 0/,
        "m=video 9 UDP/TLS/RTP/SAVPF 96\r\na=rtpmap:96 VP8/90000",
      );
    }
    return sdp;
  }, []);

  const start = useCallback(async (streamText: string) => {
    if (
      (peerConnectionRef.current?.signalingState === "stable" ||
        peerConnectionRef.current?.iceConnectionState === "connected") &&
      isStreamReadyRef.current
    ) {
      try {
        await services.streaming.startStream(
          streamIdRef.current!,
          sessionIdRef.current!,
          streamText,
        );
      } catch (error) {
        console.error("Error starting stream:", error);
      }
    }
  }, []);

  const destroy = useCallback(async () => {
    try {
      await services.streaming.destroyStream(
        streamIdRef.current!,
        sessionIdRef.current!,
      );
    } catch (error) {
      console.error("Error destroying stream:", error);
    }
    stopAllStreams();
    closePC();
  }, [stopAllStreams, closePC]);

  useEffect(() => {
    connect();
    if (isBrowserDefined()) {
      (window as any).ReactNativeWebView?.postMessage("its working lool");
      window.addEventListener("message", onMessageReceived);
      document.addEventListener("message", onMessageReceived);
    }
    const idleVideoElement = idleVideoRef.current;
    if (idleVideoElement) {
      idleVideoElement.muted = true;
      idleVideoElement.play();
    }

    return () => {
      destroy();
      if (isBrowserDefined()) {
        window.removeEventListener("message", onMessageReceived);
        document.removeEventListener("message", onMessageReceived);
      }
    };
  }, [connect, destroy]);

  const onMessageReceived = (message: any) => {
    if (!message.data) return;
    (window as any).ReactNativeWebView?.postMessage(message.data);
    console.log(message.data);
    start(message.data);
  };

  return (
    <div className="w-[100%] relative flex justify-center items-center">
      <div
        className={
          "relative w-[270px] h-[270px] flex justify-center items-center"
        }
      >
        {!hideIdeal && (
          <video
            ref={idleVideoRef}
            id="idleVideoElement"
            loop
            muted
            onCanPlay={() => {
              idleVideoRef.current?.play();
            }}
            onLoadedMetadata={() => {
              if (idleVideoRef.current) {
                idleVideoRef.current.muted = true;
              }
            }}
            className="absolute top-0 left-0 w-[270px] h-[270px] object-contain rounded-full"
            style={{ opacity: streamVideoOpacity ? 0 : 1 }}
          >
            <source
              src={"/assets/videos/rian_idle.30611ac06ee62baa763f.mp4"}
              type="video/mp4"
            />
          </video>
        )}
        <video
          ref={streamVideoRef}
          id="streamVideoElement"
          autoPlay
          preload="metadata"
          webkit-playsinline="webkit-playsinline"
          playsInline
          onLoadedMetadata={useCallback(() => {
            if (streamVideoRef.current) {
              streamVideoRef.current.muted = false;
            }
          }, [])}
          className="absolute top-0 left-0 w-[270px] h-[270px] object-contain rounded-full"
          style={{ opacity: streamVideoOpacity ? 1 : 0 }}
        />
      </div>
    </div>
  );
};

export default Streaming;
