import { createSlice } from "@reduxjs/toolkit";
import { LoadingState } from "../../interfaces/connected-components/loading";

const initialState: LoadingState = {
  isVisible: false,
};

/**
 * Redux slice for managing loading state.
 */
export const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    /**
     * Action to show loading indicator.
     * @param {LoadingState} state - Current loading state.
     */
    showLoading: (state: LoadingState) => {
      state.isVisible = true;
    },
    /**
     * Action to hide loading indicator.
     * @param {LoadingState} state - Current loading state.
     */
    hideLoading: (state: LoadingState) => {
      state.isVisible = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { showLoading, hideLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
