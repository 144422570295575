import React, { useEffect, useState } from "react";
import generateMeta from "../../../shared/services/metadataService";
import metaData from "../../../config/metaData";
import Streaming from "./streaming";
import { useLocation, useParams } from "react-router-dom";

/**
 * Home component displays a simple "Home works" message.
 * @returns {JSX.Element} The Home component JSX
 */
const Home: React.FC = (): JSX.Element => {
  const location = useLocation();
  const [hideIdeal, setHideIdeal] = useState(true);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const hideIdealParam = queryParams.get("hideIdeal");
    setHideIdeal(hideIdealParam === "true");
  }, [location.search]);

  return (
    <div>
      {generateMeta({
        title: metaData.home.title,
        description: metaData.home.description,
      })}
      <Streaming hideIdeal={hideIdeal} />
    </div>
  );
};

export default Home;
